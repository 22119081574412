var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"topActionButtonPrimary":{
    text: 'Save',
    key: 'saveFaq',
    disabled: _vm.formIsValid,
    loading: _vm.makingApiRequest
  },"topActionButtonSecondary":{
    text: 'Cancel',
    key: 'cancelFaq',
    loading: _vm.isLoading
  },"element-loading-text":_vm.loadingText},on:{"toolbarSelectItem":_vm.toolbarSelectItem,"errorMessageClosed":function($event){_vm.errorMessage = ''},"successMessageClosed":function($event){_vm.successMessage = ''}}},[_c('div',{staticClass:"mb-8 md:mb-12 flex justify-between"},[_c('div',[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900"},[_vm._v(" FAQ Details ")]),_c('p',{staticClass:"mt-1 text-sm text-gray-500"},[_vm._v(" Provide some information about the FAQ. ")])])]),_c('div',[_c('SelectField',{ref:"atlasPlatform_field",attrs:{"label":"Select Atlas Platform","fixedLabels":true,"filterable":true,"options":_vm.atlasPlatformOptions,"required":true,"multiple":true},on:{"change":function($event){return _vm.editField({ key: 'atlasPlatformIds', value: $event })}}}),_c('TextField',{ref:"question",attrs:{"label":"Question","placeholder":'Type your question here',"value":_vm.editing?.question,"required":true},on:{"input":function($event){return _vm.editField({ key: 'question', value: $event })}}})],1),_c('div',[_c('label',{staticClass:"font-medium text-gray-600"},[_vm._v("Answer")]),_c('ContentEditor',{key:_vm.attachmentId,ref:"editor",attrs:{"editorFor":"answer","imagePreviewToken":"","initialData":_vm.editing?.answer,"label":"Answer","uploadFn":_vm.uploadFunction,"attachmentId":_vm.attachmentId},on:{"change":_vm.handleChange}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }