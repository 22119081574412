
import {
  faqMapActions,
  faqMapMutations,
  faqMapState
} from "@/store/modules/faq";
import * as types from "@/store/mutation-types";
import Vue from "vue";
import { atlasfileMapActions } from "@/store/modules/atlasfile";
import { atlasPlatformMapActions } from "@/store/modules/atlasPlatform";
import { IAtlasPlatform } from "@/store/modules/atlasPlatform/types";

interface IData {
  errorMessage: string;
  successMessage: string;
  isLoading: boolean;
  loadingText: string;
  atlasPlatforms: IAtlasPlatform[];
  attachmentId: string;
  file: any;
  formData: FormData;
  isSaving: boolean;
}
export default Vue.extend({
  name: "createFaq",
  components: {
    ContentEditor: () => import("@/components/TinyEditor/TinyEditor.vue")
  },
  data(): IData {
    return {
      errorMessage: "",
      successMessage: "",
      isLoading: false,
      loadingText: "",
      atlasPlatforms: [],
      attachmentId: "",
      file: null,
      formData: new FormData(),
      isSaving: false
    };
  },
  created() {
    this.SET_EDIT({} as any);
  },
  mounted() {
    this.getFetchAtlasPlatforms();
  },
  computed: {
    ...faqMapState(["editing", "makingApiRequest"]),
    formIsValid(): boolean {
      return !(this.editing?.question && this.editing.answer);
    },
    atlasPlatformOptions(): {
      label: string;
      value: string;
    }[] {
      const options = this.atlasPlatforms.map((platform: IAtlasPlatform) => {
        return {
          label: platform.name,
          value: platform._id
        };
      });
      return options;
    }
  },
  methods: {
    ...faqMapMutations(["SET_EDIT"]),
    ...faqMapMutations({ editField: types.SET_EDIT_FIELD }),
    ...atlasfileMapActions(["createAtlasfile"]),
    ...faqMapActions(["createFaq"]),
    ...atlasPlatformMapActions(["getAtlasPlatforms"]),
    async getFetchAtlasPlatforms() {
      try {
        const data = await this.getAtlasPlatforms({});
        this.atlasPlatforms = data;
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    async toolbarSelectItem(event: string) {
      switch (event) {
        case "saveFaq":
          await this.submit();
          break;
        case "cancelFaq":
          this.$router.push("/admin/faqs").catch(() => {});
          break;
      }
    },
    createFormData(file: any) {
      const formData = new FormData();
      formData.append("file", file.blob(), file.filename());
      formData.append("name", "");
      formData.append("isPublic", "false");
      formData.append("category", "None");
      formData.append("type", "attachment");
      return formData;
    },
    async uploadFunction(file: any) {
      this.file = file;
    },
    async handleChange(value: string) {
      this.editField({ key: "answer", value });
      if (this.isSaving) {
        this.doSave();
      }
    },

    async doSave() {
      try {
        await this.createFaq(this.editing);
        this.$router.replace("/admin/faqs");
        this.SET_EDIT({} as any);
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      } finally {
        this.isSaving = false;
      }
    },
    async submit(): Promise<void> {
      try {
        this.loadingText = "Creating FAQ. Please wait...";
        this.isLoading = true;
        if (this.file) {
          const response = await this.createAtlasfile(
            this.createFormData(this.file)
          );
          this.attachmentId = response._id;
          this.editField({ key: "attachments", value: [response._id] });
          this.isSaving = true;
        } else {
          this.doSave();
        }
      } catch (err) {
        this.errorMessage = err.message;
        this.$bugSnagClient.notify(err);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    }
  }
});
